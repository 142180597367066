import React from 'react';

function Blogs(props) {
    return (
        <div className="rr-blog-area pt-90 pb-90 fix">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 wow rrfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">
                        <div className="rr-blog-title-box text-center mb-45">
                     <span className="rr-section-subtitle p-relative"><img src="assets/img/testimonial/section-icon.png"
                                                                           alt="img"/> Blogs <img
                         src="assets/img/testimonial/section-icon.png"
                         alt="img"/></span>
                            <h4 className="rr-section-title">Latest News Health and Medical</h4>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30 wow rrfadeUp" data-wow-duration=".9s"
                         data-wow-delay=".3s">
                        <div className="rr-blog-item">
                            <div className="rr-blog-thumb-main p-relative">
                                <a href="blog-details.html">
                                    <div className="rr-blog-thumb">
                                        <img src="assets/img/blog/blog04.jpg" alt="img"/>
                                        <span>Employment</span>
                                    </div>
                                </a>
                            </div>
                            <div className="rr-blog-content rr-blog-content-opcity p-relative">
                                <div className="rr-blog-content-info d-flex mb-15">
                                            <span><a href="blog-details.html"><i
                                                className="fa-sharp fa-solid fa-comments"></i> 02 Comments</a></span>
                                    <span className="ml-30"><i className="fa-regular fa-calendar-days"></i> Apr - 32 -
                              2024</span>
                                </div>
                                <div className="rr-blog-text">
                                    <h4 className="rr-blog-title mb-0 pb-10"><a href="blog-details.html">Improve
                                        Assistant Health
                                        Medical This Blog</a></h4>
                                    <p>We will give you a complete account
                                        theen teachings of the great.</p>
                                </div>
                                <div className="rr-blog-wrap d-flex align-items-center justify-content-between">
                                    <div className="rr-blog-item-user d-flex align-items-center">
                                      
                                    </div>
                                    <div className="rr-blog-link">
                                        <a href="blog-details.html">See More <i
                                            className="fa-light fa-angle-right"></i></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30 wow rrfadeUp" data-wow-duration=".9s"
                         data-wow-delay=".5s">
                        <div className="rr-blog-item">
                            <div className="rr-blog-thumb-main p-relative">
                                <a href="blog-details.html">
                                    <div className="rr-blog-thumb">
                                        <img src="assets/img/blog/blog05.jpg" alt="img"/>
                                        <span>Employment</span>
                                    </div>
                                </a>
                            </div>
                            <div className="rr-blog-content rr-blog-content-opcity p-relative">
                                <div className="rr-blog-content-info d-flex mb-15">
                                            <span><a href="blog-details.html"><i
                                                className="fa-sharp fa-solid fa-comments"></i> 02 Comments</a></span>
                                    <span className="ml-30"><i className="fa-regular fa-calendar-days"></i> Apr - 32 -
                              2024</span>
                                </div>
                                <div className="rr-blog-text">
                                    <h4 className="rr-blog-title mb-0 pb-10"><a href="blog-details.html">How We
                                        Can Fix Problem in
                                        Tailbone in Back</a></h4>
                                    <p>We will give you a complete account
                                        theen teachings of the great.</p>
                                </div>
                                <div className="rr-blog-wrap d-flex align-items-center justify-content-between">
                                    <div className="rr-blog-item-user d-flex align-items-center">
                                       
                                       
                                    </div>
                                    <div className="rr-blog-link">
                                        <a href="blog-details.html">See More <i
                                            className="fa-light fa-angle-right"></i></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30 wow rrfadeUp" data-wow-duration=".9s"
                         data-wow-delay=".7s">
                        <div className="rr-blog-item">
                            <div className="rr-blog-thumb-main p-relative">
                                <a href="blog-details.html">
                                    <div className="rr-blog-thumb">
                                        <img src="assets/img/blog/blog06.jpg" alt="img"/>
                                        <span>Employment</span>
                                    </div>
                                </a>
                            </div>
                            <div className="rr-blog-content rr-blog-content-opcity p-relative">
                                <div className="rr-blog-content-info d-flex mb-15">
                                            <span><a href="blog-details.html"><i
                                                className="fa-sharp fa-solid fa-comments"></i> 02 Comments</a></span>
                                    <span className="ml-30"><i className="fa-regular fa-calendar-days"></i> Apr - 32 -
                              2024</span>
                                </div>
                                <div className="rr-blog-text">
                                    <h4 className="rr-blog-title mb-0 pb-10"><a href="blog-details.html">Medical
                                        Which Helps This
                                        Medical This Blog</a></h4>
                                    <p>We will give you a complete account
                                        theen teachings of the great.</p>
                                </div>
                                <div className="rr-blog-wrap d-flex align-items-center justify-content-between">
                                    <div className="rr-blog-item-user d-flex align-items-center">
                                       
                                        
                                    </div>
                                    <div className="rr-blog-link">
                                        <a href="blog-details.html">See More <i
                                            className="fa-light fa-angle-right"></i></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="blog-btn text-center wow rrfadeUp pt-20" data-wow-duration=".9s"
                         data-wow-delay=".9s"
                         style={{
                             visibility: "visible",
                             animationDuration: "0.9s",
                             animationDelay: "0.9s",
                             animationName: "rrfadeUp"
                         }}>
                        <a className="rr-btn-black" href="team.html"><span>View all Blog <i
                            className="fa-sharp fa-solid fa-plus"></i></span></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blogs;