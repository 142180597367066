import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Features from "../components/Features";
import AboutMeIntro from "../components/AboutMeIntro";
import Services from "../components/Services";
import Slider from "../components/Slider";
import Blogs from "../components/Blogs";

function HomePage(props) {

    return (
        <div>
            <Header/>
            <main>
                <Slider/>
                <Features/>
                <AboutMeIntro/>
                <Services/>
                <Blogs/>
            </main>
            <Footer/>
        </div>
    );
}

export default HomePage;