import logo from './logo.svg';
import './App.css';
import HomePage from "./pages/HomePage";
function App() {
  return (
      <div className="App">
        <HomePage />
      </div>
  );
}

export default App;
