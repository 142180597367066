import React from 'react';

function Services(props) {
    return (
        <section className="rr-service-1-area grey-bg pt-100 pb-100 fix">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 wow rrfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">
                        <div className="rr-price-2-title-box text-center mb-20">
                     <span className="rr-section-subtitle p-relative"><img src="assets/img/testimonial/section-icon.png"
                                                                           alt="img"/> Our Services <img
                         src="assets/img/testimonial/section-icon.png"
                         alt="img"/></span>
                            <h4 className="rr-section-title">Experts in Range Medical Services</h4>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 wow rrfadeUp"
                         data-wow-duration=".9s" data-wow-delay=".3s">
                        <div className="rr-service-1-item p-relative fix mb-15">
                            <div className="rr-service-1-thumb p-relative z-index-2">
                                <img className="w-100" src="assets/img/service/bg-1-1.jpg" alt="img"/>
                                <div className="rr-service-1-icon-main p-relative">
                                    <div className="rr-service-1-icon">
                                        <img className="icon" src="assets/img/service/medicine.png" alt="img"/>
                                    </div>
                                </div>
                            </div>
                            <div className="rr-service-1-content">
                                <h3 className="rr-service-1-title"><a href="service-details.html">Medicine &
                                    Health</a></h3>
                                <p>Medical is the knowledge or master event
                                    dentify the error of the we website
                                    best coding page speed.</p>
                                <div className="rr-service-1-list">
                                    <ul className="d-flex align-items-center">
                                        <li><i className="fa-solid fa-check"></i> <a
                                            href="service-details.html">Medicine & Health</a></li>
                                        <li className="ml-20"><i className="fa-solid fa-check"></i><a
                                            href="service-details.html">Equipment</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 wow rrfadeUp"
                         data-wow-duration=".9s" data-wow-delay=".5s">
                        <div className="rr-service-1-item p-relative fix mb-15">
                            <div className="rr-service-1-thumb p-relative z-index-2">
                                <img className="w-100" src="assets/img/service/bg-1-2.jpg" alt="img"/>
                                <div className="rr-service-1-icon-main p-relative">
                                    <div className="rr-service-1-icon">
                                        <img className="icon" src="assets/img/service/medicine2.png" alt="img"/>
                                    </div>
                                </div>
                            </div>
                            <div className="rr-service-1-content">
                                <h3 className="rr-service-1-title"><a href="service-details.html">Medical
                                    Counseling</a></h3>
                                <p>Medical is the knowledge or master event
                                    dentify the error of the we website
                                    best coding page speed.</p>
                                <div className="rr-service-1-list">
                                    <ul className="d-flex align-items-center">
                                        <li><i className="fa-solid fa-check"></i> <a
                                            href="service-details.html">Medicine & Health</a></li>
                                        <li className="ml-20"><i className="fa-solid fa-check"></i><a
                                            href="service-details.html">Equipment</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 wow rrfadeUp"
                         data-wow-duration=".9s" data-wow-delay=".7s">
                        <div className="rr-service-1-item p-relative fix mb-15">
                            <div className="rr-service-1-thumb p-relative z-index-2">
                                <img className="w-100" src="assets/img/service/bg-1-3.jpg" alt="img"/>
                                <div className="rr-service-1-icon-main p-relative">
                                    <div className="rr-service-1-icon">
                                        <img className="icon" src="assets/img/service/medicine3.png" alt="img"/>
                                    </div>
                                </div>
                            </div>
                            <div className="rr-service-1-content">
                                <h3 className="rr-service-1-title"><a href="service-details.html">Economical
                                    Choice</a></h3>
                                <p>Medical is the knowledge or master event
                                    dentify the error of the we website
                                    best coding page speed.</p>
                                <div className="rr-service-1-list">
                                    <ul className="d-flex align-items-center">
                                        <li><i className="fa-solid fa-check"></i> <a
                                            href="service-details.html">Medicine & Health</a></li>
                                        <li className="ml-20"><i className="fa-solid fa-check"></i><a
                                            href="service-details.html">Equipment</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 wow rrfadeUp"
                         data-wow-duration=".9s" data-wow-delay=".7s">
                        <div className="rr-service-1-item p-relative fix ">
                            <div className="rr-service-1-thumb p-relative z-index-2">
                                <img className="w-100" src="assets/img/service/bg-1-4.jpg" alt="img"/>
                                <div className="rr-service-1-icon-main p-relative">
                                    <div className="rr-service-1-icon">
                                        <img className="icon" src="assets/img/service/medicine7.png" alt="img"/>
                                    </div>
                                </div>
                            </div>
                            <div className="rr-service-1-content">
                                <h3 className="rr-service-1-title"><a href="service-details.html">Injection
                                    Syringe</a></h3>
                                <p>Medical is the knowledge or master event
                                    dentify the error of the we website
                                    best coding page speed.</p>
                                <div className="rr-service-1-list">
                                    <ul className="d-flex align-items-center">
                                        <li><i className="fa-solid fa-check"></i> <a
                                            href="service-details.html">Medicine & Health</a></li>
                                        <li className="ml-20"><i className="fa-solid fa-check"></i><a
                                            href="service-details.html">Equipment</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 wow rrfadeUp"
                         data-wow-duration=".9s" data-wow-delay=".5s">
                        <div className="rr-service-1-item p-relative fix ">
                            <div className="rr-service-1-thumb p-relative z-index-2">
                                <img className="w-100" src="assets/img/service/bg-1-5.jpg" alt="img"/>
                                <div className="rr-service-1-icon-main p-relative">
                                    <div className="rr-service-1-icon">
                                        <img className="icon" src="assets/img/service/medicine5.png" alt="img"/>
                                    </div>
                                </div>
                            </div>
                            <div className="rr-service-1-content">
                                <h3 className="rr-service-1-title"><a href="service-details.html">Qualified
                                    Doctors</a></h3>
                                <p>Medical is the knowledge or master event
                                    dentify the error of the we website
                                    best coding page speed.</p>
                                <div className="rr-service-1-list">
                                    <ul className="d-flex align-items-center">
                                        <li><i className="fa-solid fa-check"></i> <a
                                            href="service-details.html">Medicine & Health</a></li>
                                        <li className="ml-20"><i className="fa-solid fa-check"></i><a
                                            href="service-details.html">Equipment</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 wow rrfadeUp "
                         data-wow-duration=".9s"
                         data-wow-delay=".3s">
                        <div className="rr-service-1-item p-relative fix ">
                            <div className="rr-service-1-thumb p-relative z-index-2">
                                <img className="w-100" src="assets/img/service/bg-1-6.jpg" alt="img"/>
                                <div className="rr-service-1-icon-main p-relative">
                                    <div className="rr-service-1-icon">
                                        <img className="icon" src="assets/img/service/medicine6.png" alt="img"/>
                                    </div>
                                </div>
                            </div>
                            <div className="rr-service-1-content">
                                <h3 className="rr-service-1-title"><a href="service-details.html">Science
                                    Laboratory</a></h3>
                                <p>Medical is the knowledge or master event
                                    dentify the error of the we website
                                    best coding page speed.</p>
                                <div className="rr-service-1-list">
                                    <ul className="d-flex align-items-center">
                                        <li><i className="fa-solid fa-check"></i> <a
                                            href="service-details.html">Medicine & Health</a></li>
                                        <li className="ml-20"><i className="fa-solid fa-check"></i><a
                                            href="service-details.html">Equipment</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;