import React from 'react';

function Features(props) {
    return (
        <section className="rr-features-area pt-90 pb-70 fix">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 wow rrfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">
                        <div className="rr-features-title-box text-center mb-45">
                     <span className="rr-section-subtitle p-relative"><img src="assets/img/testimonial/section-icon.png"
                                                                           alt="img"/> Our features Us <img
                         src="assets/img/testimonial/section-icon.png"
                         alt="img"/></span>
                            <h4 className="rr-section-title">Experts in Every Aspect Service</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                        <div className="rr-features-item p-relative wow rrfadeUp" data-wow-duration=".9s"
                             data-wow-delay=".3s">
                            <div className="rr-features-thumb p-relative">
                                <img src="assets/img/feature/bg-img.png" alt="img"/>
                                <span>01</span>
                            </div>
                            <div className="rr-features-icon">
                                <img src="assets/img/feature/icon-1.png" alt="img"/>
                            </div>
                            <div className="rr-features-content text-center">
                                <h3 className="rr-features-title"><a href="service-details.html">Test-Bottle</a>
                                </h3>
                                <p>Medical is the knowledge or master
                                    event. Identify the error of the we
                                    coding page speed.</p>
                                <a className="rr-features-btn" href="service-details.html"><span>See
                              More <i className="fa-solid fa-angle-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                        <div className="rr-features-item p-relative wow rrfadeUp" data-wow-duration=".9s"
                             data-wow-delay=".5s">
                            <div className="rr-features-thumb p-relative">
                                <img src="assets/img/feature/bg-img.png" alt="img"/>
                                <span>02</span>
                            </div>
                            <div className="rr-features-icon">
                                <img src="assets/img/feature/icon-2.png" alt="img"/>
                            </div>
                            <div className="rr-features-content text-center">
                                <h3 className="rr-features-title"><a href="service-details.html">Chest Lungs</a>
                                </h3>
                                <p>Medical is the knowledge or master
                                    event. Identify the error of the we
                                    coding page speed.</p>
                                <a className="rr-features-btn" href="service-details.html"><span>See
                              More <i className="fa-solid fa-angle-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                        <div className="rr-features-item p-relative wow rrfadeUp" data-wow-duration=".9s"
                             data-wow-delay=".7s">
                            <div className="rr-features-thumb p-relative">
                                <img src="assets/img/feature/bg-img.png" alt="img"/>
                                <span>03</span>
                            </div>
                            <div className="rr-features-icon">
                                <img src="assets/img/feature/icon-3.png" alt="img"/>
                            </div>
                            <div className="rr-features-content text-center">
                                <h3 className="rr-features-title"><a href="service-details.html">Test
                                    Laboratory</a></h3>
                                <p>Medical is the knowledge or master
                                    event. Identify the error of the we
                                    coding page speed.</p>
                                <a className="rr-features-btn" href="service-details.html"><span>See
                              More <i className="fa-solid fa-angle-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                        <div className="rr-features-item p-relative wow rrfadeUp" data-wow-duration=".9s"
                             data-wow-delay=".9s">
                            <div className="rr-features-thumb p-relative">
                                <img src="assets/img/feature/bg-img.png" alt="img"/>
                                <span>04</span>
                            </div>
                            <div className="rr-features-icon">
                                <img src="assets/img/feature/icon-4.png" alt="img"/>
                            </div>
                            <div className="rr-features-content text-center">
                                <h3 className="rr-features-title"><a href="service-details.html">Team
                                    Support</a></h3>
                                <p>Medical is the knowledge or master
                                    event. Identify the error of the we
                                    coding page speed.</p>
                                <a className="rr-features-btn" href="service-details.html"><span>See
                              More <i className="fa-solid fa-angle-right"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Features;