import React from 'react';
import HeaderTopArea from "./HeaderTopArea";
import HeaderMainArea from "./HeaderMainArea";
import HeaderMenu from "./HeaderMenu";

function Header(props) {
    return (
        <div>
            <HeaderTopArea/>
            <HeaderMainArea/>
            <HeaderMenu/>
        </div>

    );
}

export default Header;