import React from 'react';

function HeaderTopArea(props) {
    return (
       
        <div className="rr-header-1-top rr-header-1-shap d-none d-xl-block grey-bg p-relative">
            <div className="container custom-container-1 z-index-3">
                <div className="rr-header-1-before">
                    <div className="row align-items-center">
                        <div className="col-xxl-5 col-xl-6 col-lg-6">
                            <div className="rr-header-1-top-info text-end">
                                <ul className="d-flex align-items-center">
                                    <li>
                                        <a href="mailto:support@gmail.com "><span><i
                                            className="fa-solid fa-envelope"></i></span>
                                            support@gmail.com </a>
                                    </li>
                                    <li className="ml-50">
                                        <b><span><i className="fa-solid fa-clock"></i></span>Mon - Sat
                                            10:00 AM - 08:00 PM</b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3  col-lg-1 d-none d-xxl-block">
                     {/*       <div className="rr-header-1-top-btn-text">*/}
                     {/*<span>We are open hours staff!*/}
                     {/*</span>*/}
                     {/*       </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default HeaderTopArea;