import React from 'react';

function Slider(props) {
    const imageUrl = "assets/img/slider/rs=w_1279,m.webp";
    return (
        <div className="rr-slider-1-area fix p-relative theme-bg-2 pt-90"
             style={{backgroundImage: `url(${imageUrl})`}}>
            <div className="rr-slider-1-wrapper">
                <div className="rr-slider-1-wrap p-relative">
                    <div className="rr-slider-1-height">
                        <div className="container">
                            <div className="rr-slider-1-shap-star">
                                <img src="assets/img/slider/start-shap.png" alt="img"/>
                            </div>
                            <div className="rr-slider-1-shap-plus">
                                <img src="assets/img/slider/plus-shap.png" alt="img"/>
                            </div>
                            <div className="rr-slider-1-shap-plus-2">
                                <img src="assets/img/slider/subtitle-shape-3.png" alt="img"/>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-xl-7 col-lg-7 col-md-6">
                                    <div className="rr-slider-1-content z-index-1">
                                        <div className="rr-slider-1-title-box">
                                 <span className="rr-slider-1-subtitle wow rrfadeUp" data-wow-duration=".9s"
                                       data-wow-delay=".3s"><img src="assets/img/testimonial/section-icon.png"
                                                                 alt="img"/> Dr. Varun Garg (MBBS, DNB Paediatrics)</span>
                                            <h1 className="rr-slider-1-title pb-10 wow rrfadeUp"
                                                data-wow-duration=".9s" data-wow-delay=".5s">Dedicated to the
                                                health
                                                & well-being of children.</h1>
                                            <p className="pb-30 wow rrfadeUp" data-wow-duration=".9s"
                                               data-wow-delay=".7s">We’re here for whatever comes your way.</p>
                                        </div>
                                        <div className="rr-slider-1-button-box d-flex align-items-center">
                                            <a className="rr-btn-1 mr-30 wow rrfadeUp" data-wow-duration=".9s"
                                               data-wow-delay=".7s" href="contact.html"><span>Contact Us <i
                                                className="fa-sharp fa-solid fa-plus"></i></span></a>
                                            <a className="rr-btn-black wow rrfadeUp" data-wow-duration=".9s"
                                               data-wow-delay=".9s"
                                               href="service-details.html"><span>Read MOre <i
                                                className="fa-sharp fa-solid fa-plus"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-6 wow fadeInRight"
                                     data-wow-duration=".9s" data-wow-delay=".5s">
                                    <div className="rr-slider-1-img p-relative pt-50">
                                        <div className="rr-slider-1-thumb z-index-2">
                                            <img src="assets/img/slider/varun-photo.jpeg" alt="img"/>
                                        </div>
                                        <div className="rr-slider-1-shap z-index-1">
                                            <img src="assets/img/slider/img-2.png" alt="img"/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default Slider;