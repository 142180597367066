import React from 'react';

function AboutMeIntro(props) {
    return (
        <section className="rr-home2-about pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 col-12 mb-20 wow fadeInLeft">
                        <div className="rr-section rr-style-1 text-left mb-40">
                     <span className="rr-section-subtitle p-relative"><img src="assets/img/testimonial/section-icon.png"
                                                                           alt="img"/>About Dr. Varun Garg<img
                         src="assets/img/testimonial/section-icon.png" alt="img"/></span>

                            <p className="rr-section-description">
                                Dr Varun Garg is a specialist in Paediatrics with a rich of 10 years currently working
                                in Apple Hospital, Indore.
                                He has expertise in Neonatology.
                                He has handled numerous complex medical cases and is known for her attention to detail,
                                accurate diagnosis, and treating patients with empathy.
                            </p>
                        </div>
                        <div className="row mt-5 mb-20">
                            <div className="col-lg-6 col-md-6 col-6">
                                <div className="rr-info-box1">
                                    <div className="rr-info-box-icon mb-15">
                                        <img src="assets/img/feature/icon-3.png" alt="img"/>
                                    </div>
                                    <div className="rr-info-box1-info">
                                        <h5 className="rr-info-title">Infection Prevention</h5>
                                        <p className="rr-infobox-description">It is a long established fact that
                                            a reader will be
                                            distracted. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-6">
                                <div className="rr-info-box1">
                                    <div className="rr-info-box-icon mb-15">
                                        <img src="assets/img/feature/icon-4.png" alt="img"/>
                                    </div>
                                    <div className="rr-info-box1-info">
                                        <h5 className="rr-info-title">Inpatient Services</h5>
                                        <p className="rr-infobox-description">It is a long established fact that
                                            a reader will be
                                            distracted. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rr-button-block">
                            <a className="rr-btn-1" href="about.html"><span>Read More <i
                                className="fa-sharp fa-solid fa-plus"></i></span></a>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="about-us-img">
                            <div className="rr-about-2-experience wow fadeInRight">
                                <span>10+ Years Experience</span>
                            </div>
                            <img src="assets/img/about/img-9.jpg"
                                 className="img-fluid rr-image1 wow fadeInRight" alt="home"/>
                            <img src="assets/img/about/img-10.jpg"
                                 className="img-fluid rr-image2 wow fadeInRight" alt="home"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutMeIntro;