import React from 'react';

function HeaderMainArea(props) {
    return (
        <div>
            <section className="rr-header-1-main d-none d-xl-block">
                <div className="container custom-container-1">
                    <div className="row align-items-center">
                        <div className="col-xl-4">
                            <div className="rr-header-main-social d-flex align-items-center">
                                <h4 className="rr-header-1-title">Follow Social:</h4>
                                <div className="rr-header-1-social ml-20">
                                    <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                    <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                    <a href="#"><i className="fa-brands fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="rr-herader-1-logo text-center">
                                <a href="index.html"><img src="assets/img/logo/logo-new-193.png" alt="img"/></a>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="rr-header-1-contat d-flex align-items-center justify-content-end">
                                <div className="rr-header-1-contat-icon p-relative">
                                    <span><i className="fa-solid fa-phone"></i></span>
                                </div>
                                <div className="rr-header-1-contat-text ml-20">
                                    <h4>Appointment Call</h4>
                                    <b><a href="tel:+99584554">+91 70009 76018</a></b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default HeaderMainArea;