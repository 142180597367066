import React from 'react';

function Footer(props) {
    return (
        <div>
            <footer>
                <div className="rr-footer-main p-relative fix" data-background="assets/img/footer/footer-bg.jpg">

                    
                    <div className="rr-footer-area pt-125 p-relative fix">
                        <div className="rr-footer-right-shap d-none d-xl-block">
                            <img src="assets/img/footer/footer-shap.png" alt="img"/>
                        </div>
                        <div className="container">
                            <div className="rr-footer-border">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 mb-50 wow rrfadeUp"
                                         data-wow-duration=".9s"
                                         data-wow-delay=".3s">
                                        <div className="rr-footer-widget footer-cols-1">
                                            <div className="rr-footer-logo pb-35">

                                            </div>
                                            <div className="rr-footer-widget-content-list mb-25">
                                                <div className="rr-footer-widget-content-list-item">
                                                    <i className="fa-sharp fa-solid fa-clock"></i> <a href="#">Open
                                                    Hours of Government:<br/>
                                                    Mon - Sat
                                                    10:00 AM - 08:00 PM.</a>
                                                </div>
                                                <div className="rr-footer-widget-content-list-item">
                                                    <i className="fa-solid fa-location-dot"></i> <a href="#">Apple Care
                                                    Hospital ,
                                                    Transport Nagar,<br/> Indore, Madhya Pradesh .</a>
                                                </div>
                                                <div className="rr-footer-widget-content-list-item">
                                                    <i className="fa-sharp fa-solid fa-phone"></i><a
                                                    href="tel:+91 70009 76018">+91 70009 76018</a>
                                                </div>
                                            </div>
                                            <div className="rr-footer-social">
                                                <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                                <a href="#"><i className="fa-brands fa-pinterest-p"></i></a>
                                                <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                                <a href="#"><i className="fa-brands fa-instagram"></i></a>
                                            </div>
                                        </div>
                                    </div>

                                   
                                    <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 mb-50 wow rrfadeUp"
                                         data-wow-duration=".9s"
                                         data-wow-delay=".9s" style={{marginLeft:"400px"}}>
                                        <div className="rr-footer-widget footer-cols-4">
                                            <h4 className="rr-footer-title">Opening Schedule</h4>
                                            <div className="rr-footer-contact">
                                                <ul>
                                                    <li><span>Saturday -------------------------- Closed</span></li>
                                                    <li><span>Sunday --------------------------  Closed</span></li>
                                                    <li><span>Monday ---------- 10.00 AM - 8.00 PM IST</span></li>
                                                    <li><span>Tuesday ----------10.00 AM - 8.00 PM IST</span></li>
                                                    <li><span>Wednesday ------- 10.00 AM - 8.00 PM IST</span></li>
                                                    <li><span>Thrusday ---------10.00 AM - 8.00 PM IST</span></li>
                                                    <li><span>Friday ---------- 10.00 AM - 8.00 PM IST</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rr-copyright-area p-relative">
                        <div className="container rr-copyright-broder rr-copyright-space">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12 wow rrfadeUp" data-wow-duration=".9s"
                                     data-wow-delay=".3s">
                                    <div className="rr-copyright-left text-center text-md-start">
                                        <p>Copyright © 2024 <a href="#"> Dr. Varun Garg, </a> All Rights Reserved.</p>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12 wow rrfadeUp" data-wow-duration=".9s"
                                     data-wow-delay=".5s">
                                    <div className="rr-copyright-right text-center text-md-end">
                                        <a href="about.html">About Us </a>
                                        <a href="service-details.html">Events</a>
                                        <a href="blog.html">News</a>
                                        <a href="service-details.html">Portfolio</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;