import React from 'react';

function HeaderMenu(props) {
    return (
        <div>
            <header className="rr-header-height z-index-3">

                <div id="header-sticky" className="rr-header-area">
                    <div className="container custom-container-1 rr-header-bg">
                        <div className="row align-items-center">
                            <div className="col-xl-7 col-lg-7 col-md-6 col-6">
                                <div className="rr-header-1-logo d-xl-none">
                                    <img src="assets/img/logo/logo-black.png" alt="img"/>
                                </div>
                                <div className="rr-header-main-menu rr-header-menu ml-30  d-none d-xl-block">
                                    <nav className="rr-main-menu-content">
                                        <ul>
                                            <li>
                                                <a href="index.html">Home</a>
                                           
                                            </li>
                                            <li><a href="about.html">about us</a></li>
                                            <li className="has-dropdown">
                                                <a href="service-details.html">Service</a>
                                                <ul className="submenu rr-submenu">
                                                    <li><a href="service-details.html">Service</a></li>
                                                    <li><a href="service-details.html">Service Details</a></li>
                                                </ul>
                                            </li>
                                          <li><a href="contact.html">Contact</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-6 col-6">
                                <div className="rr-header-right d-flex align-items-center justify-content-end">
                                    {/*<div className="rr-header-icon d-none rr-header-2-search d-xl-block">*/}
                                    {/*    <button className="search-open-btn"><i*/}
                                    {/*        className="fa-solid fa-magnifying-glass"></i></button>*/}
                                    {/*</div>*/}
                                    <div className="rr-header-1-contat d-none d-md-block ml-30">
                                        <a className="rr-btn-border" href="contact.html"><span>Make Appointment <i
                                            className="fa-sharp fa-solid fa-plus"></i></span></a>
                                    </div>
                                    <div className="rr-header-bar d-xl-none">
                                        <button className="rr-menu-bar"><i className="fa-solid fa-bars"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </header>
        </div>
    );
}

export default HeaderMenu;